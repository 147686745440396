<template>
  <ion-slides pager="true" :options="slideOpts">

    <ion-slide
      v-for="media in medias"
      :key="media"
      @click="sendClose()"
    >
      <img :src="media.url" @click="imageClicked()"/>
      <ion-button class="hidden-button"></ion-button>
    </ion-slide>

  </ion-slides>
</template>

<script>
import {
  IonSlides, IonSlide, IonButton
} from '@ionic/vue'

export default {
  name: 'IllustrationsModal',
  components: {
    IonSlides, IonSlide, IonButton
  },
  props: [
    'medias'
  ],
  data() {
    return {
      slideOpts: {
        initialSlide: 0,
        speed: 400
      },
      imagePressed: false
    }
  },
  methods: {
    sendClose () {
      if(!this.imagePressed) {
        this.$emit('closedFromModal',false)
      } else {
        this.imagePressed = false
      }
    },
    imageClicked () {
      this.imagePressed = true
    }
  }
}
</script>

<style scoped>
ion-slides {
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  --bullet-background: #60588f;
  --bullet-background-active: #ffffff;
}
.hidden-button{
  display: none;
}
</style>
