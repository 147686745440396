<template>
  <div>
    <span v-bind:class="`${stylename} ${position} badge rounded-pill`">{{ stylename }}</span>
  </div>
</template>

<script>
export default {
  props: [
    'stylename',
    'position'
  ]
}
</script>

<style scoped>
.badge{
  color: #595959;
  background-color: var(--ion-color-light);
}
.float-right, .right{
  margin: 10px 0 0 15px;
}
.normal{
  display: block;
  float: none;
  margin: 10px 15px 0 0;
}
.float-left, .left{
  margin: 10px 15px 0 0;
}
</style>
