<template>
  <master-layout
    bgColor="white"
  >

    <ion-fab
      vertical="top"
      horizontal="start"
      slot="fixed"
      @click="$router.go(-1)"
    >
      <ion-fab-button class="backbutton" color="light">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <div
      v-if="winery && winery.Medias && winery.Medias.length > 0"
    >
      <img
        :src="winery.Medias[0].url"
        @load="onImgLoad()"
        @click="modalOpen=true"
        class="main-image"
      />
      <div
        class="view-photos"
        v-if="showContent"
      >
        <p>Fotók megtekintése</p>
      </div>

      <ion-modal
        :is-open="modalOpen"
        css-class="photo-modal"
        animated=false
        v-if="showContent"
      >
        <PhotoGalleryModal
          :data="data"
          :medias="winery.Medias"
          @closedFromModal="modalOpen=false"
          show-backdrop=false
        />
      </ion-modal>
    </div>
    <img v-else-if="winery" src="assets/images/obt-default-illustration.png" class="main-image"/>

      <ion-grid
        v-if="winery && showContent"
      >

      <ion-row class="winery-section">

        <ion-img
          v-if="winery.Logo"
          :src="winery.Logo.url"
          class="wineryLogo"
        />

        <h1 class="wineryName">{{ winery.Name }}</h1>

        <ion-label>
          <h4>
            <span
              class="regionTitle"
              v-for="region in winery.Wineregions"
              :key="region.id"
            >
              {{ region.Title }}
            </span>
            <span
              v-if="winery.WineryProfile"
              class="separator"
            >
              •
            </span>
            <span
              v-if="winery.WineryProfile"
              :class="winery.WineryProfile.Title"
            >
              {{ winery.WineryProfile.Title }}
            </span>
          </h4>
        </ion-label>

      </ion-row>

      <ion-row
        v-if="showRatings"
        class="winery-section rating"
      >
        <ion-item
          lines="none"
          class="realTransparent"
          button
          @click="goToRatings()"
        >
          <RatingSummary
            :ratings="winery.Ratings"
          />
        </ion-item>

      </ion-row>

      <ion-row class="winery-section">
        <ion-col>
          <img class="icon iconleft" src="assets/images/icon-terulet.svg" alt="Terület">
          <p class="surfaceProduction">
            <b>Terület</b><br/>
            <span v-if="winery.Surface" class="blue">{{ winery.Surface }} ha</span>
            <span v-else class="blue">n.a.</span>
          </p>
        </ion-col>
        <ion-col>
          <img class="icon iconleft" src="assets/images/icon-palackszam.svg" alt="Palackszám">
          <p class="surfaceProduction">
            <b>Éves palackszám</b><br/>
            <span v-if="winery.Production" class="blue">{{ winery.Production }}</span>
            <span v-else class="blue">n.a.</span>
          </p>
        </ion-col>
      </ion-row>

      <ion-row
        v-if="winery.Description"
        class="winery-section"
      >
        <div class="wineryDescription" v-html="winery.Description"></div>
      </ion-row>

      <ion-row
        v-if="winery.WineryStyles && winery.WineryStyles.length > 0"
        class="winery-section"
      >
        <h3>A borászat borstílusai</h3>
        <WinemakerListStyles
          v-for="style in winery.WineryStyles"
          :key="style.id"
          :stylename="style.Title"
          position="normal"
        />
      </ion-row>

      <ion-row
        class="winery-section-dark"
        v-if="winery.Addresses"
      >
        <h1>Elérhetőségek</h1>
        <div v-if="winery.Addresses">

          <WinemakerAddresses
            v-for="address in winery.Addresses"
            :key="address"
            :address="address"
          />

        </div>
      </ion-row>

    </ion-grid>

  </master-layout>
</template>

<script>
import axios from 'axios'
import {
  IonGrid, IonRow, IonCol,
  IonFab, IonFabButton, IonIcon, IonImg, IonLabel, IonItem,
  IonModal
} from '@ionic/vue'
import { addIcons } from 'ionicons'
import {
  arrowBack
} from 'ionicons/icons'
import WinemakerListStyles from '@/components/WinemakerListStyles.vue'
import WinemakerAddresses from '@/components/WinemakerAddresses.vue'
import PhotoGalleryModal from '@/components/PhotoGalleryModal.vue'
import RatingSummary from '@/components/RatingSummary.vue'
import { mapActions } from 'vuex'

export default {
  name: 'CheckinConfirm',
  components: {
    IonGrid, IonRow, IonCol,
    IonFab, IonFabButton, IonIcon, IonImg, IonLabel, IonItem,
    IonModal,
    WinemakerListStyles, WinemakerAddresses, PhotoGalleryModal, RatingSummary
  },
  data () {
    return {
      wineryId: null,
      winery: null,
      showContent: false,
      modalOpen: false,
      showRatings: false
    }
  },
  created() {
    addIcons({
      'arrow-back': arrowBack
    })
  },
  ionViewWillEnter() {
    // initialze:
    window.scrollTo(0, 0)
    if(this.$route.params.id != this.wineryId) {
      this.showContent = false
      this.winery = null
      this.showRatings = false
      this.wineryId = this.$route.params.id
      this.getWinery()
    }
  },
  methods: {
    ...mapActions('wineries', {
      setWineryInMemory: 'setWineryInMemory'
    }),
    async getWinery () {
      var thisWinery = await axios.get(process.env.VUE_APP_STRAPI_URI + 'wineries/' + this.wineryId)
      this.winery = thisWinery.data
      if(this.winery.Medias && this.winery.Medias.length > 0){
        // then it's onImgLoad() that will showContent
      } else {
        this.showContent = true
      }
      if(this.winery.Ratings && this.winery.Ratings.length > 0){
        this.showRatings = true
      }
    },
    // Image always loads after winery
    onImgLoad () {
      this.showContent = true
    },
    goToRatings () {
      this.setWineryInMemory(this.winery)
      this.$router.push('/boraszat-ertekelesek')
    }
  }
}
</script>

<style scoped>
ion-grid{
  color: #333333;
  padding: 0px;
  clear: both;
}
ion-row{
  --margin: 0px;
  padding: 20px 20px;
}
ion-row.rating{
  padding: 0px;
}
h3{
  font-family: Alphabet;
  font-size: 16px !important;
  width: 100%;
}
.winery-section{
  border-bottom: 1px solid #cccccc;
}
.winery-section-dark{
  background-color: var(--ion-color-darkpurple);
}
/* colors inside .winery-section-dark to be found inside customstyle.css */
.icon{
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: 7px;
}
.iconleft{
  float: left;
  margin-bottom: 10px;
}
h1.wineryName{
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}
.wineryDescription >>> p, .surfaceProduction{
  font-size: 14px;
  line-height: 20px;
}
.blue{
  color: var(--ion-color-primary);
}
.wineryLogo{
  max-width: 40%;
  max-height: 70px;
}
.backbutton{
  margin-top: calc(10px + env(safe-area-inset-top));
}
.main-image{
  border-radius: 0 0 20px 20px;
}
.view-photos{
  background-color: rgba(0,0,0,0.6);
  display: inline-block;
  float: right;
  position: relative;
  bottom: 40px;
  right: 25px;
  margin-bottom:-30px;
}
.view-photos p{
  font-size:13px;
  color: #ffffff;
  margin: 0;
  padding: 5px;
}
</style>
