<template>

  <ion-row class="section-row">
    <h3>
      {{ address.Title }}
    </h3>
  </ion-row>
  <ion-row>
    <ion-col>
      <img class="icon" src="assets/images/icon-cim-pink.svg" alt="Cím">
      <span><b>Cím</b></span>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <p class="spacer">{{ address.Address }}</p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <div class="mapcontainer spacer" lazy>
        <l-map
          :zoom="initialZoom"
          :center="center"
          ref="mymap"
          class="map"
          :options="{zoomControl: false}"
          @ready="mapReady()"
        >
          <!-- <l-tile-layer url="https://api.mapbox.com/styles/v1/gergohidegfoldi/ckptf6scg0xmv17n34ebz3mf1/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ2VyZ29oaWRlZ2ZvbGRpIiwiYSI6ImNrMzQ3cGhnNDB4OXAzbXA5dzViaTIzZXQifQ._zvL9Ah99V-qEiaiccz0RA"></l-tile-layer> -->
          <l-tile-layer url="https://api.mapbox.com/styles/v1/obt2021/ckz5iigum002s14s5qxiw2v2a/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib2J0MjAyMSIsImEiOiJja3AydDV2eWswM3d3MnBsbzRyZGUxdXRwIn0.toLxd_rcor15o3t_t-fy7A"></l-tile-layer>
            <l-marker
              :lat-lng="[address.x, address.y]"
            >
              <l-icon
                :icon-size="[37, 56]"
                :icon-anchor="[18, 51]"
                v-bind:iconUrl="'/assets/images/pin-obt-red.svg'"
              />
            </l-marker>
         </l-map>
       </div>
     </ion-col>
  </ion-row>

  <ion-row
    v-if="address.OpeningHours"
    class="section-row"
  >
    <ion-col>
      <img class="icon" src="assets/images/icon-nyitvatartas-pink.svg" alt="Nyitvatartás">
      <span><b>Nyitvatartás</b></span>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <div class="openingHours spacer" v-html="address.OpeningHours" />
    </ion-col>
  </ion-row>

  <!-- <ion-row
    v-if="address.Phone"
    class="section-row"
  >
    <ion-col size="6">
      <img class="icon" src="assets/images/icon-telefon-pink.svg" alt="Telefon">
      <span><b>Telefon</b></span>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <p class="spacer">
          {{ address.Phone }}
      </p>
    </ion-col>
  </ion-row> -->

  <ion-row
    v-if="address.PhoneNumbers && address.PhoneNumbers.length > 0"
    class="section-row"
  >
    <ion-col size="6">
      <img class="icon" src="assets/images/icon-telefon-pink.svg" alt="Telefon">
      <span><b>Telefon</b></span>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <p class="spacer">
        <span
          v-for="phone in address.PhoneNumbers"
          :key="phone"
        >
          <a :href="`tel:${phone.Phone}`" target="_system">
            {{ phone.Phone }}
          </a>
          &nbsp;
        </span>
      </p>
    </ion-col>
  </ion-row>

  <ion-row
    v-if="address.Email"
    class="section-row"
  >
    <ion-col size="6">
      <img class="icon" src="assets/images/icon-email-pink.svg" alt="Email">
      <span><b>Email</b></span>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <p class="spacer">
        <a :href="`mailto:${address.Email}`" target="_system">
          {{ address.Email }}
        </a>
      </p>
    </ion-col>
  </ion-row>

  <ion-row
    v-if="address.Website"
    class="section-row"
  >
    <ion-col size="6">
      <img class="icon" src="assets/images/icon-web-pink.svg" alt="Web">
      <span><b>Web</b></span>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <p class="spacer">
        <a :href="address.Website" target="_system">
          {{ address.Website }}
        </a>
      </p>
    </ion-col>
  </ion-row>

  <ion-row
    v-if="address.SocialMedias.length != 0"
    class="section-row"
  >
    <ion-col size="6">
      <img class="icon" src="assets/images/icon-kozossegi-media-pink.svg" alt="Közösségi Média">
      <span><b>Közösségi média</b></span>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <p
        v-for="socialmedia in address.SocialMedias"
        :key="socialmedia.Title"
        class="spacer"
      >
        <a v-if="socialmedia.URL" :href="socialmedia.URL" target="_system">
          {{ socialmedia.Title }}
        </a>
      </p>
    </ion-col>
  </ion-row>

  <ion-row
    v-if="address.Info"
    class="section-row"
  >
    <ion-col size="6">
      <img class="icon" src="assets/images/icon-infos-pink.svg" alt="Info">
      <span><b>Információk</b></span>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <p class="spacer">{{ address.Info }}</p>
    </ion-col>
  </ion-row>

</template>

<script>
import 'leaflet/dist/leaflet.css'
import {
  LMap, LTileLayer, LMarker, LIcon
} from '@vue-leaflet/vue-leaflet'
import {
  IonRow, IonCol
} from '@ionic/vue'

export default {
  props: [
    'address'
  ],
  components: {
    LMap, LTileLayer, LMarker, LIcon,
    IonRow, IonCol
  },
  data () {
    return {
      initialZoom: 10
    }
  },
  computed: {
    center () {
      return {lat: this.address.x, lng: this.address.y}
    }
  },
  ionViewDidEnter () {
    this.cleanupLeafletRender()
  },
  methods: {
    mapReady () {
      this.cleanupLeafletRender()
    },
    cleanupLeafletRender () {
      if (this.$refs.mymap && this.$refs.mymap.leafletObject && this.$refs.mymap.leafletObject.options){
        let sizeInvalidatedResp = this.$refs.mymap.leafletObject.invalidateSize()
        if(sizeInvalidatedResp._size.x == 0){
          setTimeout(() => {
            this.cleanupLeafletRender()
          }, 10)
        }
      }
    }
  }
}
</script>

<style scoped>
span, p, .openingHours >>> p{
  font-size: 14px;
}
h3{
  font-family: Alphabet;
  font-size: 16px !important;
}
ion-row{
  margin: 0;
  padding: 0;
}
ion-col{
  margin: 0;
  padding: 0;
}
.section-row{
  margin-top: 10px;
}
.icon{
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: 4px;
}
.spacer{
  margin-left: 24px;
}
.mapcontainer{
  width: 78vw;
  height: 20vh;
}
.mapcontainer img {
    max-height: none;
}
.map{
  width: 78vw;
  height: 20vh;
  background: #faf5e6;
}
</style>
